import { withPage } from '~/components/page'
import ColumnDetailView from '~/views/ColumnDetail/ColumnDetail'

const ArchivedPage = () => {
	return (
		<ColumnDetailView
			archiveView
			columnData={{
				columnId: 'closed',
				heading: 'Archived',
				leadTeam: undefined,
				stageTypes: [],
				archive: true,
			}}
		/>
	)
}

export default withPage(ArchivedPage, {
	public: false,
	name: 'Archived Details',
})
